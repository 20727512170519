import { Box, Flex, Image, Text, useDisclosure } from "@chakra-ui/react";
import { IoLocationSharp } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import ModalContacts from "./ModalContacts";

export default function Contacts(): JSX.Element {

    const { isOpen, onClose } = useDisclosure()

    return (
        <Flex
            h={{ base: 'auto', md: 'auto', lg: '600px' }}
            p={{ base: '40px', md: '40px  100px', lg: '40px  100px' }}
            justify={'space-between'}
            my={{ base: '20px', md: '10px', lg: '40px' }}
            flexDir={{ base: 'column', md: 'column', lg: 'row' }}
        >
            <Flex
                w={{ base: '100%', md: '100%', lg: '40%' }}
                h={'100%'}
                flexDir={'column'}
                textAlign={{ base: 'center', md: 'left', lg: 'left' }}
            >
                <Text
                    color={'red.500'}
                    fontSize={{ base: '10pt', md: '12pt', lg: '12pt' }}
                    fontWeight={'600'}
                    textTransform="uppercase"
                >
                    Somos a Toque Média
                </Text>
                <Text
                    w={'100%'}
                    fontSize={{ base: '16pt', md: '26pt', lg: '28pt' }}
                    mb={{ base: '20px', md: '30px', lg: '50px' }}
                >
                    Gostaria de saber como podemos ajudar a transformar sua ideia em realidade?
                </Text>

                <Flex
                    flexDir={'row'}
                    align="center"
                    color={'#000'}
                    mb={4}
                    px={{base:'0px', md:'20px', lg:'20px'}}
                >
                    <IoLocationSharp size={20} color={'red.500'} />
                    <Text ml={'20px'} fontSize={{ base: '15px', md: '15px', lg: '18px' }}>Rua 13, Zona 3, Benfica, Luanda</Text>
                </Flex>
                <Flex
                    flexDir={'row'}
                    align="center"
                    color={'#000'}
                    mb={4}
                    px={{base:'0px', md:'20px', lg:'20px'}}
                >
                    <FaPhoneAlt size={20} color={'red.500'} />
                    <Text ml={'20px'} fontSize={{ base: '15px', md: '15px', lg: '18px' }} >(+244 921785735)</Text>
                </Flex>
                <Flex
                    flexDir={'row'}
                    align="center"
                    color={'#000'}
                    px={{base:'0px', md:'20px', lg:'20px'}}
                    mb={{ base: '10px', md: '30px', lg: '50px' }}
                >
                    <MdEmail size={20} color={'red.500'} />
                    <Text ml={'20px'} fontSize={{ base: '15px', md: '15px', lg: '18px' }} >geral@toquemedia.net</Text>
                </Flex>
{/* 
                <Button
                    variant="outline"
                    color="red.500"
                    borderColor="red.500"
                    borderWidth="2px"
                    borderRadius="5pt"
                    fontSize={{base:'10pt' ,md:'13pt' ,lg:'13pt' }}
                    w={{base:"160px" ,md:"220px" ,lg:"220px" }}
                    h={{base:'40px' ,md:'60px' ,lg:'60px' }}
                    p={{base:'20px' ,md:'20px' ,lg:'20px' }}
                    mt={4}
                    _hover={{ bg: "red.50" }}
                    _active={{ bg: "red.100" }}
                    onClick={() => { onOpen() }}>
                    Solicite atendimento
                </Button> */}
            </Flex>
            <Box
                w={{ base: '100%', md: '100%', lg: '50%' }}
                h={'100%'}
                mt={{ base: '20px', md: '0%', lg: '0%' }}
            >
                <Image borderRadius={'20px'} src="./img3.png" />
            </Box>
            <ModalContacts isOpen={isOpen} onClose={onClose} />

        </Flex>

    );
}
