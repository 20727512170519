import { Box, Flex, Text } from "@chakra-ui/react";

interface CardType {
    title: string,
    text: string,
    n: string
}

export default function Consultancy(): JSX.Element {

    const arr: CardType[] = [
        {
            n: '1',
            title: "Transformando Ideias em Acções",
            text: 'O primeiro passo é entender a fundo suas necessidades e desafios. Realizamos uma análise detalhada para identificar oportunidades e estabelecer objectivos claros para o projecto.'
        },
        {
            n: '2',
            title: "Criação e Planejamento",
            text: 'Com base nas informações colectadas, nossa equipe desenvolve um plano estratégico, combinando inovação e funcionalidade. Transformamos ideias em soluções visuais e estruturadas.'
        },
        {
            n: '3',
            title: "Desenvolvimento e Implementação",
            text: 'Colocamos o plano em ação com um desenvolvimento ágil e eficiente. Cuidamos de todos os detalhes, desde a arquitectura até a integração de sistemas, garantindo uma entrega de alta qualidade.'
        }
    ];

    return (
        <Box
            p={{ base: '20px 20px', md: '80px 100px', lg: '80px  100px' }}
            h={{ base: 'auto', md: 'auto', lg: '90vh' }}
            textAlign={{ base: 'center', md: 'left', lg: 'left' }}
        >
            <Box mb={'30px'} w={{ base: 'auto', md: '60%', lg: '80%' }}>
                <Text
                    textTransform="uppercase"
                    color={'red.500'}
                    fontSize={{ base: '10pt', md: '12pt', lg: '12pt' }}
                    fontWeight={'700'}
                >
                    Nossa Abordagem de Sucesso
                </Text>
                <Text
                    fontSize={{ base: '16pt', md: '26pt', lg: '30   px' }}
                >
                    Desde a concepção até a entrega, trabalhamos em parceria contigo para criar soluções que impulsionam o seu negócio.
                </Text>
            </Box>
            <Flex
                align={'center'}
                justify={'center'}
            >
                <Box overflowX={'hidden'} width={'90%'} p={{ base: '0px', md: '0', lg: '20px' }}>
                    <Flex gap={{ base: 0, md: 10, lg: 20 }} flexDir={{ base: 'column', md: 'row', lg: 'row' }} justify={'space-between'}>
                        {arr.map((val: CardType, i: number) => (
                            <CardConsultancy key={i} {...val} />
                        ))}
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );
}

function CardConsultancy({ title, text, n }: CardType) {
    return (
        <Flex
            bg={'#f6f6f6'}
            p={'30px 20px'}
            direction={'column'}
            w={{ base: '100%', md: '320px', lg: '370px' }}
            h={{ base: 'auto', md: 'auto', lg: '340px' }}
            my={{ base: '20px', md: '0', lg: '0' }}
            borderRadius={'10px'}
            boxShadow={'1px 1px 10px 1px rgba(0, 0, 0, 0.1)'}
        >
            <Flex 
                w={'100%'}
                align={{base:'left', md:'center',lg:'center'}}
                mb={'20px'}
            >
               <Box w={'20%'}>
               <Text
                    color={'#fff'}
                    bg={'#000'}
                    w={'35px'}
                    h={'35px'}
                    mr={'20px'}
                    borderRadius={'30pt'}
                    textAlign={'center'}
                    fontSize={{base:'15pt', md:'18pt', lg:'18pt'}}
                    lineHeight={'35px'}
                >{n}</Text>
               </Box>
                <Text
                    fontSize={{base:'14pt', md:'20pt', lg:'20pt'}}
                    fontWeight={'600'}
                    color={'gray.800'}
                >{title}</Text>
            </Flex>
            <Text
                fontSize={{base:'11pt', md:'14pt', lg:'14pt'}}
                color={'gray.700'}
            >{text}</Text>
        </Flex>
    );
}