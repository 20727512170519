import { Box, Flex, Image, Text } from "@chakra-ui/react";

export default function OurService(): JSX.Element {
    return (
        <Box
            p={{ base: '30px', md: '40px  100px', lg: '50px  100px' }}
            textAlign={'center'}
        >
            <Text
                color={'red.500'}
                p={{ base: '0px 20px', md: '10px 40px', lg: '0' }}
                w={'100%'}
                fontSize={{ base: '10pt', md: '12pt', lg: '12pt' }}
                fontWeight={'600'}
                textTransform="uppercase"
            >
                Nossos Serviços
            </Text>
            <Text
                w={'100%'}
                mb={{ base: '30px', md: '50px', lg: '50px' }}
                fontSize={{ base: '20pt', md: '26pt', lg: '28pt' }}
                p={{ base: '0px 20px', md: '0px 40px', lg: '0' }}
            >
                Transformamos ideias em soluções reais.
            </Text>

            <Flex
                justify={'space-between'}
                flexDir={{ base: 'column', md: 'column', lg: 'row' }}
            >
                <Flex
                    w={{ base: '100%', md: '100%', lg: '50%' }}
                    h={'100%'}
                    justify={'center'}
                    alignSelf={'center'}
                    flexDir={'column'}
                    textAlign={{ base: 'center', md: 'left', lg: 'left' }}
                >
                    <Text
                        color={'red.500'}
                        w={'100%'}
                        fontSize={{ base: '10pt', md: '12pt', lg: '12pt' }}
                        fontWeight={'600'}
                        textTransform="uppercase"
                    >
                        Desenvolvimento Web
                    </Text>
                    <Text
                        w={'100%'}
                        fontSize={{ base: '15pt', md: '18pt', lg: '22pt' }}
                    >
                        Criamos aplicativos web personalizados para atender às necessidades específicas do seu negócio.
                    </Text>
                    <Text
                        fontSize={{ base: '13pt', md: '16pt', lg: '16pt' }}
                        p={{ base: '10px 20px', md: '0px 40px', lg: '10px 0px' }}
                        mb={{ base: '20px', md: 0, lg: 0 }}>
                        Nosso time de desenvolvedores altamente experientes está preparado para transformar suas ideias em soluções inovadoras, robustas e escaláveis, alinhadas às suas necessidades e objetivos.
                    </Text>
                </Flex>
                <Box
                    w={{ base: '100%', md: '100%', lg: '40%' }}
                    h={{ base: '100%', md: '100%', lg: '400px' }}
                >
                    <Image borderRadius={'20px'} src="./ZENTAAK.png" />
                </Box>
            </Flex>

            <Flex
                py={{ base: '40px', md: '50px', lg: '50px' }}
                justify={'space-between'}
                flexDir={{ base: 'column', md: 'column', lg: 'row-reverse' }}
            >
                <Flex
                    w={{ base: '100%', md: '100%', lg: '50%' }}
                    h={'100%'}
                    justify={'center'}
                    alignSelf={'center'}
                    flexDir={'column'}
                    textAlign={{ base: 'center', md: 'left', lg: 'left' }}
                >
                    <Text
                        color={'red.500'}
                        w={'100%'}
                        fontSize={{ base: '10pt', md: '12pt', lg: '12pt' }}
                        fontWeight={'600'}
                        textTransform="uppercase"
                    >
                        Aplicativos Móveis
                    </Text>
                    <Text
                        w={'100%'}
                        fontSize={{ base: '15pt', md: '18pt', lg: '22pt' }}
                    >
                        Desenvolvemos aplicativos móveis que proporcionam experiências incríveis para os utilizadores.
                    </Text>
                    <Text
                        fontSize={{ base: '13pt', md: '16pt', lg: '16pt' }}
                        p={{ base: '10px 20px', md: '0px 40px', lg: '10px 0px' }}
                        mb={{ base: '20px', md: 0, lg: 0 }}
                    >
                        Seja para Android ou iOS, nossos aplicativos móveis são projectados para garantir o máximo desempenho e uma usabilidade excepcional.
                    </Text>
                </Flex>
                <Box
                    w={{ base: '100%', md: '100%', lg: '40%' }}
                    h={{ base: '100%', md: '100%', lg: '400px' }}
                >
                    <Image borderRadius={'20px'} src="./TOQUEPLAY.png" />
                </Box>
            </Flex>

            <Flex
                justify={'space-between'}
                flexDir={{ base: 'column', md: 'column', lg: 'row' }}
            >
                <Flex
                    w={{ base: '100%', md: '100%', lg: '50%' }}
                    h={'100%'}
                    justify={'center'}
                    alignSelf={'center'}
                    flexDir={'column'}
                    textAlign={{ base: 'center', md: 'left', lg: 'left' }}
                >
                    <Text
                        color={'red.500'}
                        w={'100%'}
                        fontSize={{ base: '10pt', md: '12pt', lg: '12pt' }}
                        fontWeight={'600'}
                        textTransform="uppercase"
                    >
                        Aplicativos Desktop
                    </Text>
                    <Text
                        w={'100%'}
                        fontSize={{ base: '15pt', md: '18pt', lg: '22pt' }}
                    >
                        Fornecemos soluções de software desktop personalizadas para otimizar os processos do seu negócio.
                    </Text>
                    <Text fontSize={{ base: '13pt', md: '16pt', lg: '16pt' }}
                        p={{ base: '10px 20px', md: '0px 40px', lg: '10px 0px' }}
                        mb={{ base: '20px', md: 0, lg: 0 }}
                    >
                        Nossos aplicativos desktop são projectados para garantir segurança, desempenho e facilidade de uso.
                    </Text>
                </Flex>
                <Box
                    w={{ base: '100%', md: '100%', lg: '40%' }}
                    h={{ base: '100%', md: '100%', lg: '400px' }}
                >
                    <Image borderRadius={'20px'} src="./MOMENU.png" />
                </Box>
            </Flex>
        </Box>
    )
}
