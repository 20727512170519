import { Box, Flex, Text, Image, keyframes } from "@chakra-ui/react";
import { useState } from "react";

const scrollAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 5));
  }
`;

export default function OurClients(): JSX.Element {

    const arr = [
        './baiclient.png',
        './arenaclient.png',
        './dinheiroclient.png',
        './mepclient.png',
        './sonangolclient.png',
        './pay4all.webp',
        './reshapeclient.png',
        './menosfiosclient.png',
        './bravuz.jpeg',
        './sga.png',
    ];

    const extendedArr = [...arr, ...arr];

    const [isHovered, setIsHovered] = useState(false);

    return (
        <Box
            p={{ base: '30px 10px', md: '10px 70px', lg: '50px 10px' }}
            h={{ base: 'auto', md: '70vh', lg: '70vh' }}
            textAlign={{ base: 'center', md: 'left', lg: 'left' }}
            bg={'#f6f6f6'}
        >
            <Box mb={{ base: 10, md: 20, lg: 20 }}>
                <Text
                color={'red.500'}
                p={{ base: '0px 20px', md: '0px 40px', lg: '0px 90px' }}
                w={{ base: '100%', md: '100%', lg: '40%' }}
                fontSize={{ base: '10pt', md: '12pt', lg: '12pt' }}
                fontWeight={'600'}
                textTransform="uppercase"
                mt={'30px'}
            >
                Nossos clientes são
            </Text>
            <Text
                p={{ base: '0px 20px', md: '0px 40px', lg: '0px 90px' }}
                w={{ base: '100%', md: '100%', lg: '60%' }}
                fontSize={{ base: '20pt', md: '26pt', lg: '28pt' }}
                mb={{ base: '10px', md: '30px', lg: '0px' }}
            >
                Nossos parceiros
            </Text>
            </Box>
            <Flex
                w={'100%'}
                overflow="hidden"
                sx={{
                    "::-webkit-scrollbar": {
                        display: "none",
                    },
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <Flex
                    py={'10px'}
                    w={'max-content'}
                    animation={!isHovered ? `${scrollAnimation} 20s linear infinite` : 'none'}
                >
                    {extendedArr.map((value: string, i: number) => (
                        <CardClient key={i} val={value} />
                    ))}
                </Flex>
            </Flex>
        </Box>
    );
}

function CardClient({ val }: { val: string }) {
    return (
        <Flex
            bg={'#fff'}
            justify={'center'}
            align={'center'}
            w={{ base: '150px', md: '200px', lg: '250px' }}
            borderRadius={'20px'}
            h={{ base: '110px', md: '130px', lg: '160px' }}
            boxShadow={'1px 1px 10px 1px rgba(0, 0, 0, 0.1)'}
            fontSize={'14pt'}
            m="0 20px"
        >
            <Image w={'55%'} src={val} />
        </Flex>
    );
}
